<script lang="ts" setup>
import ulubele from "../assets/images/ulubele.svg";
import error from "../assets/images/error.svg";
const { $TolgeeProvider } = useNuxtApp();
</script>

<template>
  <component :is="$TolgeeProvider">
    <main class="flex flex-col absolute inset-0 bg-white p-4">
      <div class="h-[calc(100dvh)] flex flex-col">
        <div class="grow mx-auto flex flex-col justify-center">
          <img :src="ulubele" alt="Ulubele" class="w-40 mx-auto" />
          <img :src="error" alt="error" class="w-40 mx-auto my-16" />
          <slot />
        </div>
        <slot name="footer" />
      </div>
    </main>
  </component>
</template>
